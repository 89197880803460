(function ($) {
    const CONTRAST_TOGGLE_CLASSNAME = 'contrast-switch';
    const CONTRAST_TOGGLE_ACTIVE_CLASSNAME = 'contrast-switch--active';
    const BODY_CONTRAST_CLASSNAME = 'contrast';
    const CONTRAST_STATE_KEYNAME = 'contrast-switch';

    $.contrastSwitcher = {
        init() {
            const contrastToggleElements = Array.from(document.getElementsByClassName(CONTRAST_TOGGLE_CLASSNAME));
            let isContrastMode = sessionStorage.getItem(CONTRAST_STATE_KEYNAME);
            if (isContrastMode !== null) {
                isContrastMode = isContrastMode === 'true';
            } else {
                isContrastMode = window.matchMedia('(prefers-contrast: more)').matches;
            }

            contrastToggleElements.forEach((toggle) => {
                toggle.addEventListener('click', () => {
                    isContrastMode = !isContrastMode;
                    sessionStorage.setItem(CONTRAST_STATE_KEYNAME, isContrastMode.toString());
                    setContrastMode();
                });
            });
            setContrastMode();

            function setContrastMode() {
                document.body.classList.toggle(BODY_CONTRAST_CLASSNAME, isContrastMode);
                contrastToggleElements.forEach((toggle) =>
                    toggle.classList.toggle(CONTRAST_TOGGLE_ACTIVE_CLASSNAME, isContrastMode),
                );
            }
        },
    };
})(jQuery);
