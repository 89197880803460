/**
 * Created by wrock on 02.09.15.
 */

/**
 * Comment
 */
function addProduct(uid) {
    var _uri =
        $('#p' + uid)
            .closest('[data-compare-add]')
            .data('compareAdd') ||
        $('#p' + uid)
            .find('[data-compare-add]')
            .data('compareAdd');

    $.ajax({
        url: _uri,
        success: function (result) {
            try {
                result = jQuery.parseJSON(result);
            } catch {
                // we dont need to parse a json to a json
                // this might have changed in the past.
            }

            if ($('.tx-ubookproducts').length > 0) {
                $('.tx-ubookproducts .btnMore').removeClass('d-none');
            }
        },
    });
}

function removeProduct(uid) {
    var _uri =
        $('#p' + uid).data('compareRemove') ||
        $('#p' + uid)
            .closest('[data-compare-remove]')
            .data('compareRemove') ||
        $('#p' + uid)
            .find('[data-compare-remove]')
            .data('compareRemove');

    $('#p' + uid + ' .btnMore').addClass('d-none');

    $.ajax({
        url: _uri,
        success: function (result) {
            try {
                result = jQuery.parseJSON(result);
            } catch {
                // we dont need to parse a json to a json
                // this might have changed in the past.
            }

            var _elementsToRemove = $('[data-remove-uid="' + uid + '"]');
            _elementsToRemove.remove();

            if ($('.tx-ubookproducts').length > 0) {
                $('.tx-ubookproducts .btnMore').addClass('d-none');
            }

            if ($('.count').length > 0) {
                var _newCount = $('thead th[data-remove-uid]').length;
                $('.count').text(_newCount);
            }

            if (result.productlist === '') {
                $('[data-compare-add] .btnMore').addClass('d-none');
                $('.table-responsive').remove();
                $('.no-products').removeClass('d-none');
                $('.visible-products').remove();
            }
        },
        error: function (error) {
            console.error(error);
            $('#p' + uid + ' .btnMore').removeClass('d-none');
        },
    });
}

function removeAllFromList() {
    $.ajax({
        url: $('[data-compare-reset]').data('compareReset'),
        success: function (result) {
            try {
                result = jQuery.parseJSON(result);
            } catch {
                // we dont need to parse a json to a json
                // this might have changed in the past.
            }

            if (result && result.status == 'ok') {
                $('.table-responsive').remove();
                $('.no-products').removeClass('d-none');
                $('.visible-products').remove();
            }
        },
    });
}

$(document).ready(function () {
    $('.addToCompareDetail').on('click', function () {
        var addUid = $(this).parent().parent().parent().data('uid') || $('[data-detail-uid]').data('detailUid');
        if ($(this).parent().find('input').is(':checked')) {
            removeProduct(addUid);
        } else {
            addProduct(addUid);
        }
    });

    $('.addToCompare').on('click', function () {
        var addUid = $(this).parent().parent().parent().data('uid');

        if ($(this).parent().find('input').is(':checked')) {
            removeProduct(addUid);
        } else {
            $(this).parent().find('.btnMore').removeClass('d-none');
            addProduct(addUid);
        }
    });

    $('.product-remove').on('click', function () {
        var removeUid = $(this).closest('[data-uid]').data('uid');
        removeProduct(removeUid);
    });

    $('#remove-all-products').click(function () {
        removeAllFromList();
        $(this).remove();
    });

    // re-check checkboxes for products beeing on compare list
    if ($('.addToCompare, .addToCompareDetail').length > 0) {
        $.ajax({
            url: $('[data-compare-get]').data('compareGet'),
            success: function (result) {
                try {
                    result = jQuery.parseJSON(result);
                } catch {
                    // we dont need to parse a json to a json
                    // this might have changed in the past.
                }

                if (result && result.productlist) {
                    $('.product input[type="checkbox"]').attr('checked', false).prop('checked', false);
                    $.each(result.productlist, function (k, v) {
                        $('input[type="checkbox"]#prod' + v)
                            .parent()
                            .find('.btnMore')
                            .removeClass('d-none');
                        $('input[type="checkbox"]#prod' + v)
                            .attr('checked', true)
                            .prop('checked', true);
                    });
                }
            },
        });
    }
});
