(function ($) {
    $.historyBack = {
        init() {
            const backButtons = Array.from(document.getElementsByClassName('news-backlink'));

            backButtons.forEach((button) => {
                button.addEventListener(
                    'click',
                    () => {
                        window.history.back();
                    },
                    false,
                );
            });
        },
    };
})(jQuery);
