(function ($) {
    const MOBILE_NAV_TOGGLE_CLASSNAME = 'top-bar__burger';
    const MOBILE_NAV_TOGGLE_ACTIVE_CLASSNAME = 'top-bar__burger--active';
    const MAIN_NAV_ID = 'main-nav';
    const MAIN_NAV_ACTIVE_CLASSNAME = 'main-nav--active';
    const SUBNAV_TOGGLE_CLASSNAME = 'main-nav__submenu-toggle';
    const SUBNAV_TOGGLE_ACTIVE_CLASSNAME = 'main-nav__submenu-toggle--active';
    const SUBNAV_LIST_ACTIVE_CLASSNAME = 'main-nav__submenu-list--active';

    const IS_MOBILE_MQ = window.matchMedia('(min-width:992px)');
    const IS_MOTION_SAFE_MQ = window.matchMedia('(prefers-reduced-motion: no-preference)');

    $.mainNav = {
        init() {
            const mainNavToggleElements = Array.from(document.getElementsByClassName(MOBILE_NAV_TOGGLE_CLASSNAME));
            const subnavToggleElements = Array.from(document.getElementsByClassName(SUBNAV_TOGGLE_CLASSNAME));
            const mainNavListItems = Array.from(document.querySelectorAll('.main-nav__list > li'));
            const mainNavElement = document.getElementById(MAIN_NAV_ID);
            const mainMenuFocusTrap = focusTrap.createFocusTrap(
                [mainNavElement, ...mainNavToggleElements.map((el) => el.parentElement).filter((el) => !!el)],
                {
                    allowOutsideClick: true,
                },
            );

            let isMobileMenuOpen = false;

            mainNavToggleElements.forEach((toggle) => {
                toggle.addEventListener('click', () => {
                    isMobileMenuOpen = !isMobileMenuOpen;
                    toggleMainMenu();
                });
            });

            subnavToggleElements.forEach((toggle) => {
                toggle.addEventListener('pointerdown', (e) => {
                    toggleSubnav(toggle);
                    console.log(e);
                });
                toggle.addEventListener('keydown', (event) => {
                    if (event.code === 'Space' || event.code === 'Enter') {
                        toggleSubnav(toggle);
                    }
                });
            });

            mainNavListItems.forEach((item) => {
                item.addEventListener('focusout', (event) => {
                    if (event.relatedTarget && !item.contains(event.relatedTarget)) {
                        const itemToggle = item.querySelector('.main-nav__submenu-toggle');
                        if (itemToggle) {
                            hideSubnav(itemToggle);
                        }
                    }
                });
            });

            mainNavElement.addEventListener('keydown', (event) => {
                if (event.code === 'Escape') {
                    isMobileMenuOpen = false;
                    toggleMainMenu();
                }
            });

            IS_MOBILE_MQ.addEventListener('change', () => {
                if (!IS_MOBILE_MQ.matches) {
                    isMobileMenuOpen = false;
                    toggleMainMenu();
                }
            });

            function toggleMainMenu() {
                mainNavToggleElements.forEach((toggle) => {
                    toggle.classList.toggle(MOBILE_NAV_TOGGLE_ACTIVE_CLASSNAME, isMobileMenuOpen);
                    toggle.setAttribute('aria-expanded', isMobileMenuOpen);
                });
                if (isMobileMenuOpen) {
                    animateShowHeight(mainNavElement, MAIN_NAV_ACTIVE_CLASSNAME);
                    mainMenuFocusTrap.activate();
                } else {
                    subnavToggleElements.forEach((toggle) => hideSubnav(toggle));
                    animateHideHeight(mainNavElement, MAIN_NAV_ACTIVE_CLASSNAME);
                    mainMenuFocusTrap.deactivate();
                }
            }

            function toggleSubnav(toggleElement) {
                if (toggleElement.getAttribute('aria-expanded') === 'true') {
                    hideSubnav(toggleElement);
                } else {
                    showSubnav(toggleElement);
                }
            }

            function showSubnav(toggleElement) {
                toggleElement.setAttribute('aria-expanded', 'true');
                toggleElement.classList.add(SUBNAV_TOGGLE_ACTIVE_CLASSNAME);
                const subnavElement = document.getElementById(toggleElement.getAttribute('aria-controls'));
                animateShowHeight(subnavElement, SUBNAV_LIST_ACTIVE_CLASSNAME);
            }

            function hideSubnav(toggleElement) {
                toggleElement.setAttribute('aria-expanded', 'false');
                toggleElement.classList.remove(SUBNAV_TOGGLE_ACTIVE_CLASSNAME);
                const subnavElement = document.getElementById(toggleElement.getAttribute('aria-controls'));
                animateHideHeight(subnavElement, SUBNAV_LIST_ACTIVE_CLASSNAME);
            }

            function animateShowHeight(element, classname) {
                requestAnimationFrame(() => {
                    element.style.height = element.scrollHeight + 'px';
                    requestAnimationFrame(() => {
                        element.classList.add(classname);
                        safeTransitionEnd(element, () => {
                            element.style.height = '';
                        });
                    });
                });
            }

            function animateHideHeight(element, classname) {
                element.style.height = element.scrollHeight + 'px';
                requestAnimationFrame(() => {
                    element.classList.remove(classname);
                    element.style.height = '';
                });
            }

            function safeTransitionEnd(element, cb) {
                if (typeof cb !== 'function') {
                    cb = () => undefined;
                }

                if (!IS_MOTION_SAFE_MQ.matches) {
                    console.log('not motion safe');
                    cb();
                    return;
                }

                const hasElementTransition = window
                    .getComputedStyle(element)
                    .getPropertyValue('transition-duration')
                    .split(',')
                    .map((duration) => parseFloat(duration))
                    .some((duration) => duration > 0);
                if (!hasElementTransition) {
                    console.log('no transitions');
                    cb();
                    return;
                }

                const eventCallback = () => {
                    element.removeEventListener('transitionend', eventCallback);
                    element.removeEventListener('transitioncancel', eventCallback);
                    cb();
                };

                element.addEventListener('transitionend', eventCallback);
                element.addEventListener('transitioncancel', eventCallback);
            }
        },
    };
})(jQuery);
