// $.youTube = {
//     init: function () {
//         // add eventlistener for supi event to change AR responsive classes and titles
//         window.addEventListener("youTubeAllowed", function (evt) {
//             $('.frame-type-tx_supi_youtube .video-wrapper').addClass('aspect-ratio-xs aspect-ratio-16by9');
//             $('.frame-type-tx_supi_youtube iframe').attr('title', $('.frame-type-tx_supi_youtube').data('title'));
//         }, false);
//     },
// };

// the event listener is not working in firefox, if its in a jquery object.
window.addEventListener(
    'youTubeAllowed',
    function (evt) {
        $('.frame-type-tx_supi_youtube .video-wrapper').addClass('aspect-ratio-xs aspect-ratio-16by9');
        $('.frame-type-tx_supi_youtube iframe').attr('title', $('.frame-type-tx_supi_youtube').data('title'));
    },
    false,
);
