$(document).ready(function () {
    $('.btnBack a, .btn-back').on('click', function (e) {
        e.preventDefault();

        var _target = document.referrer + '#p' + $(this).data('back');
        document.location.href = _target;
    });

    $('.dropdown dt a').click(function (e) {
        e.stopPropagation();
        e.preventDefault();
        $(this).parent().parent().find('ul').toggle();
    });
    $(document).click(function () {
        $('.dropdown dd ul').hide();
    });
    //if ( $( "#filter-area" ).length ) {
    //    $('#filter-area').affix({
    //        offset: {
    //            top: 450
    //        }
    //    });
    //}

    /* toggleBox */
    $('.toggleBox > .csc-header').addClass('tx-irfaq-toggle-plus');
    $('.toggleBox').children().hide();
    $('.toggleBox').find('.csc-header').show();

    $('.toggleBox > .csc-header').click(function () {
        $(this).parent().find(':nth-child(2)').slideToggle();
        $(this).toggleClass('tx-irfaq-toggle-minus tx-irfaq-toggle-plus');
    });

    $('a[data-faq!=""]').each(function (i) {
        var attr = $(this).data('faq');
        if (attr) {
            $(this).click(function () {
                $('#' + attr + ' a').trigger('click');
                $('html,body').animate({ scrollTop: $('#' + attr).offset().top - 10 });
            });
        }
    });

    var hash = window.location.hash.substr(1);
    if (hash && window.location.hash.substr(1, 9) === 'collapse_') {
        $('#faq-p-' + window.location.hash.substr(10) + ' h4 a').trigger('click');
    }

    $('.panel-body a').click(function (e) {
        var href = $(this).attr('href');
        if (href.indexOf('#collapse') > 0) {
            $('#faq-p-' + href.substr(href.indexOf('#collapse') + 10) + ' h4 a').trigger('click');
            e.preventDefault();
        }
    });

    /* irfaq */
    var plus = 'tx-irfaq-toggle-plus';
    var minus = 'tx-irfaq-toggle-minus';
    var title = '.tx-irfaq-toggle .tx-irfaq-dynheader';
    var content = '.tx-irfaq-toggle .tx-irfaq-dynans';
    var effectDuration = 250;

    $(title)
        .click(function () {
            if ($(this).next().css('display') == 'block') {
                $(this).addClass(plus).removeClass(minus);
            } else {
                $(this).addClass(minus).removeClass(plus);
            }
            $(this).next().slideToggle(effectDuration);

            return false;
        })
        .next()
        .hide();

    $('.tx-irfaq-toggle-all-show').click(function () {
        $(title).addClass(minus).removeClass(plus);
        $(content).show(effectDuration);
        return false;
    });
    $('.tx-irfaq-toggle-all-hide').click(function () {
        $(title).addClass(plus).removeClass(minus);
        $(content).hide(effectDuration);
        return false;
    });

    //slideToggle mainNav
    $('.mainMenu button').click(function () {
        $('#mainMenu').slideToggle();
    });

    //slideToggle subNav
    $('.subMenu button').click(function () {
        $('#subMenu').slideToggle();
    });

    //slideToggle product-details
    $('#headingOne').click(function () {
        $('#collapseOne').slideToggle();
        $('#collapseOne').css('overflow', 'visible');
        $(this).find('a').toggleClass('collapsed');
    });

    if (getWidth() <= 768) {
        $('#collapseOne').hide();
    }

    // open accordion
    // if (location.hash && $(location.hash + '.collapse').collapse('show')) {
    //     var acc = $(location.hash);
    //     setTimeout(function(){
    //         $('html,body').animate({scrollTop:acc.offset().top - 40,scrollLeft:acc.offset().left}, 500);
    //     }, 500);
    // }

    // tooltop for labels
    $('.active1').mouseenter(function (event) {
        var left = event.pageX - $(this).offset().left - 10;
        var top = event.pageY - $(this).offset().top + 15;
        $(this).find('span').css({ top: top, left: left, display: 'table' }).fadeIn();
    });
    $('.active1').mouseout(function () {
        $(this).find('span').css({ display: 'none' }).fadeOut();
    });

    $('.tooltip-simple').mouseenter(function (event) {
        $(this).find('span').css({ display: 'table' }).fadeIn();
    });
    $('.tooltip-simple').mouseout(function () {
        $(this).find('span').css({ display: 'none' }).fadeOut();
    });

    $(window).resize(function () {
        setFilterArea();
        //setSliderShowHeight();
    });

    // resize filter-area div
    function setFilterArea() {
        var cWidth = $('.products').width();
        $('#filter-area').css('width', cWidth);
    }

    // resize sliderheight
    function setSliderShowHeight() {
        var cHeight = $('#slideshow .image-wrapper').height();
        $('.slideshow-container').css('height', cHeight + 30);
    }

    $('.product-img').on('click', function () {
        //console.log('D');
        $('.product-gallery li').magnificPopup({
            type: 'image',
            delegate: 'a',
            autoFocusLast: true,
            focus: '.mfp-wrap',
            gallery: {
                enabled: true,
            },
        });
    });

    $('.product-gallery li').magnificPopup({
        type: 'image',
        delegate: 'a',
        autoFocusLast: true,
        focus: '.mfp-wrap',
        gallery: {
            enabled: true,
        },
    });

    if ($('.product-comparison').length) {
        function countProducts() {
            var count = $('.product > table').length;
            //console.log(count);
            $('#product-counter').html(count);
        }

        countProducts();

        //$('.product-remove').click(function() {
        //	$(this).parent().parent().parent().parent().parent().remove();
        //	countProducts ();
        //	showEmptyComparison ();
        //});

        $('#remove-all-products').click(function () {
            //    $( "#product-comparison-slider .product table" ).each(function( index ) {
            //       $(this).remove();
            //     });
            countProducts();
            showEmptyComparison();
        });

        function showEmptyComparison() {
            if ($('.slick-track').is(':empty')) {
                $('.slick-track').append('<p>Es sind keine Produkte ausgewählt.</p>');
                console.log('empty');
            }
        }

        // http://kenwheeler.github.io
        $('#product-comparison-slider').slick({
            draggable: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            prevArrow: '<button type="button" class="slick-prev"><span>Previous</span></button>',
            nextArrow: '<button type="button" class="slick-next"><span>Next</span></button>',
            responsive: [
                {
                    breakpoint: 1199,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });

        $('.product-remove').on('click', function () {
            var addUid = $(this).closest('[data-slick-index]').data('slickIndex'),
                _removeMe = $('[data-slick-index="' + addUid + '"]').index();

            $('#product-comparison-slider').slick('slickRemove', _removeMe);
            countProducts();
            showEmptyComparison();
        });
    }

    // scroll to result after search
    if (window.location.href.indexOf('filter') > -1) {
        if (window.location.href.indexOf('#p') > 0) {
            return void 0;
        }

        var elem = $('#active-filter');
        setTimeout(function () {
            $('html,body').animate({ scrollTop: elem.offset().top, scrollLeft: elem.offset().left }, 500);
        }, 500);
    }

    if (window.location.href.indexOf('produkte/zubehoer') > -1) {
        $('#solr_products').addClass('zubehoer');
    }

    if ($('#thumbs').length) {
        var onMouseOutOpacity = 0.67;
        $('#thumbs ul.thumbs li, div.navigation a.pageLink').opacityrollover({
            mouseOutOpacity: onMouseOutOpacity,
            mouseOverOpacity: 1.0,
            fadeSpeed: 'fast',
            exemptionSelector: '.selected',
        });
        // Initialize Advanced Galleriffic Gallery
        var gallery = $('#thumbs').galleriffic({
            delay: 2500,
            numThumbs: 8,
            preloadAhead: 8,
            enableTopPager: false,
            enableBottomPager: false,
            imageContainerSel: '#slideshow',
            controlsContainerSel: '#controls',
            captionContainerSel: '#caption',
            loadingContainerSel: '#loading',
            renderSSControls: true,
            renderNavControls: true,
            playLinkText: 'Play Slideshow',
            pauseLinkText: 'Pause Slideshow',
            prevLinkText: '&lsaquo; Previous Photo',
            nextLinkText: 'Next Photo &rsaquo;',
            nextPageLinkText: 'Next &rsaquo;',
            prevPageLinkText: '&lsaquo; Prev',
            enableHistory: true,
            autoStart: false,
            syncTransitions: true,
            defaultTransitionDuration: 100,
            onSlideChange: function (prevIndex, nextIndex) {
                // 'this' refers to the gallery, which is an extension of $('#thumbs')
                this.find('ul.thumbs')
                    .children()
                    .eq(prevIndex)
                    .fadeTo('fast', onMouseOutOpacity)
                    .end()
                    .eq(nextIndex)
                    .fadeTo('fast', 1.0);

                // Update the photo index display
                this.$captionContainer
                    .find('div.photo-index')
                    .html('Photo ' + (nextIndex + 1) + ' of ' + this.data.length);
            },
            onPageTransitionOut: function (callback) {
                this.fadeTo('fast', 0.0, callback);
            },
            onPageTransitionIn: function () {
                var prevPageLink = this.find('a.prev').css('visibility', 'hidden');
                var nextPageLink = this.find('a.next').css('visibility', 'hidden');

                // Show appropriate next / prev page links
                if (this.displayedPage > 0) prevPageLink.css('visibility', 'visible');

                var lastPage = this.getNumPages() - 1;
                if (this.displayedPage < lastPage) nextPageLink.css('visibility', 'visible');

                this.fadeTo('fast', 1.0);
            },
            gotoImage: function (imageData) {
                var index = imageData.index;

                if (this.onSlideChange) this.onSlideChange(this.currentImage.index, index);

                this.currentImage = imageData;
                this.preloadRelocate(index);

                //setTimeout(function() {setSliderShowHeight(); }, 1000);
                this.refresh();

                return this;
            },
        });
    }

    // scroll to top
    $(function () {
        $(document).on('scroll', function () {
            if ($(window).scrollTop() > 100) {
                $('.scroll-top-wrapper').addClass('show');
            } else {
                $('.scroll-top-wrapper').removeClass('show');
            }
        });
        $('.scroll-top-wrapper').on('click', scrollToTop);
    });

    function scrollToTop() {
        var verticalOffset = typeof verticalOffset != 'undefined' ? verticalOffset : 0,
            element = $('body'),
            offset = element.offset(),
            offsetTop = offset.top;
        $('html, body').animate({ scrollTop: offsetTop }, 500, 'linear');
    }

    setFilterArea();

    function openmagnificPopup() {
        $('.product-gallery li').magnificPopup({
            type: 'image',
            delegate: 'a',
            gallery: {
                enabled: true,
            },
        });
    }

    // $('#facet-price_without_o_s_floatS-value, #facet-price_with_o_s_floatS-value').after('<span class="currency-symbol">€</span>');

    if ($(window).width() <= 768) {
        $('.productDetails .panel-title a').trigger('click');
    }
});

function getWidth() {
    if (self.innerWidth) return self.innerWidth;
    else if (document.documentElement && document.documentElement.clientHeight)
        return document.documentElement.clientWidth;
    else if (document.body) return document.body.clientWidth;
    return 0;
}

function getHeight() {
    if (self.innerHeight) return self.innerHeight;
    else if (document.documentElement && document.documentElement.clientHeight)
        return document.documentElement.clientHeight;
    else if (document.body) return document.body.clientHeight;
    return 0;
}

function rotateBanners(elem) {
    var active = $(elem + ' a.active');
    var next = active.next();
    if (next.length == 0) next = $(elem + ' a:first');
    active.hide().removeClass('active');
    next.show().addClass('active').fadeIn(200);
}

function prepareRotator(elem) {
    $(elem + ' a')
        .hide()
        .fadeOut(0);
    $(elem + ' a:first')
        .fadeIn(0)
        .show()
        .addClass('active');
}

function startRotator(elem) {
    var first = $(elem + ' img').first();
    prepareRotator(elem);

    $(elem).css('width', $(first).width() > $(window).width() ? $(window).width() : $(first).width());
    $(elem).css('height', $(first).height());
    setInterval("rotateBanners('" + elem + "')", 4000);
}
