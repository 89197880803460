const LanguageSwitcher = {
    init: function () {
        // Append solr params to the language switcher
        // This is not the best solution, but works without changing the
        // page cache and cannot trigger cache flooding
        if (location.href.includes('?') && location.href.includes('tx_solr')) {
            const solrParams = location.href
                .split('?')
                .pop()
                .split('&')
                .filter((p) => /^tx_solr/.test(p))
                .join('&');

            document.querySelectorAll('.main-nav__list-item.main-nav__list-item--language > a').forEach((link) => {
                link.href = link.href.split('?').shift() + '?' + solrParams;
            });
        }
    },
};
