(function ($) {
    $.countdownWrapper = {
        init() {
            const _elem = $('#clock');
            const _date = _elem.data('date');

            if (_elem.length > 0 && _date) {
                _elem
                    .countdown(_date, function (event) {
                        $(this).html(
                            event.strftime(
                                '<span class="date"><span>%D</span>' +
                                    TYPO3.lang['countdown.day'] +
                                    '</span>' +
                                    '<span class="date"><span>%H</span>' +
                                    TYPO3.lang['countdown.hour'] +
                                    '</span>' +
                                    '<span class="date"><span>%M</span>' +
                                    TYPO3.lang['countdown.minute'] +
                                    '</span>' +
                                    '<span class="date"><span>%S</span>' +
                                    TYPO3.lang['countdown.second'] +
                                    '</span>',
                            ),
                        );
                    })
                    .on('update.countdown', function (event) {
                        $('.clocktext.d-none').removeClass('d-none');
                    });
            }
        },
    };
})(jQuery);
