$(() => {
    $.contrastSwitcher.init();
    $.mainNav.init();
    $.facets.init();
    $.themeSlider.init();
    $.historyBack.init();
    $.countdownWrapper.init();

    LanguageSwitcher.init();

    // removed init method due to firefox addEventListener troubles
    // added event listener in the plugin.Youtube.js directly
    // $.youTube.init();

    // implement focus-visible polyfill
    try {
        document.body.querySelector(':focus-visible');
    } catch (error) {
        var script = document.createElement('script');
        script.src = '/typo3conf/ext/theme/Resources/Public/JavaScript/focus-visible.min.js';
        document.body.appendChild(script);
    }

    $('.magnificPopup').magnificPopup({ type: 'image' });

    $.extend(true, $.magnificPopup.defaults, {
        tClose: TYPO3.lang['modal.close'], // Alt text on close button
        tLoading: TYPO3.lang['modal.loading'], // Text that is displayed during loading. Can contain %curr% and %total% keys
        gallery: {
            tPrev: TYPO3.lang['modal.keys.gallery.prev'], // Alt text on left arrow
            tNext: TYPO3.lang['modal.keys.gallery.next'], // Alt text on right arrow
            tCounter: TYPO3.lang['modal.keys.gallery.counter'], // Markup for "1 of 7" counter
        },
        image: {
            tError: TYPO3.lang['modal.image.error'], // Error message when image could not be loaded
        },
        ajax: {
            tError: TYPO3.lang['modal.ajax.error'], // Error message when ajax request failed
        },
    });

    // login link wird nicht in einer lightbox dargestellt
    // siehe ticket: https://support.sup7.at/issues/12057
    // $('.ajax-popup-link').magnificPopup({
    //     type: 'iframe',
    //     tClose: 'Schließen (Esc)',
    //     closeMarkup: '<button title="%title%" class="mfp-close">Schließen</button>'
    // });

    // this one is no longer used due to custom implementation which are removed now
    // find the file in the fileadmin/data/customHeaderData.txt file
    // $("#rslider").responsiveSlides({
    //     speed: 300,
    //     timeout: 3000,
    //     random: true
    // });
});
