(function ($) {
    const FACET_CLASSNAME = 'facet';
    const FACET_TOGGLE_CLASSNAME = 'facet__toggle';
    const FACET_ACTIVE_CLASSNAME = 'facet--active';
    const FACET_SHOW_ALL_CLASSNAME = 'facet__show-all-button';
    const FACET_OPTION_LIST_CLASSNAME = 'facet__list';
    const FACET_OPTION_LIST_ALL_CLASSNAME = 'facet__list--all';

    $.facets = {
        init() {
            const facets = Array.from(document.getElementsByClassName(FACET_CLASSNAME));
            facets.forEach((facet) => {
                const toggle = facet.querySelector('.' + FACET_TOGGLE_CLASSNAME);
                const showAllButton = facet.querySelector('.' + FACET_SHOW_ALL_CLASSNAME);
                const optionList = facet.querySelector('.' + FACET_OPTION_LIST_CLASSNAME);
                const form = facet.querySelector('form');

                facet.addEventListener('focusout', (event) => {
                    if (event.relatedTarget && !facet.contains(event.relatedTarget)) {
                        closeFacet();
                    }
                });

                facet.addEventListener('click', (event) => {
                    if (facet.contains(event.target)) {
                        event.stopPropagation();
                    }
                });

                document.addEventListener('click', () => closeFacet());

                toggle?.addEventListener('click', () => {
                    facet.classList.toggle(FACET_ACTIVE_CLASSNAME);
                    toggle.setAttribute('aria-expanded', facet.classList.contains(FACET_ACTIVE_CLASSNAME));
                });

                showAllButton?.addEventListener('click', () => {
                    const isListExpanded = optionList?.classList.toggle(FACET_OPTION_LIST_ALL_CLASSNAME);
                    if (isListExpanded) {
                        showAllButton.textContent = showAllButton.dataset.labelLess;
                    } else {
                        showAllButton.textContent = showAllButton.dataset.labelMore;
                    }
                });

                if (form) {
                    const minSlider = form.querySelector(toggle.getAttribute('data-controls') + '-lower');
                    const maxSlider = form.querySelector(toggle.getAttribute('data-controls') + '-higher');
                    const rangeGap = parseFloat(form.dataset.rangeGap) || 100;

                    if (!minSlider || !maxSlider) {
                        console.error('Could not found slider for lower value or higher value');
                        return;
                    }

                    const initialLowerValue = parseFloat(minSlider.value);
                    const initialHigherValue = parseFloat(maxSlider.value);
                    let lowerValue = initialLowerValue;
                    let higherValue = initialHigherValue;

                    form.addEventListener('submit', (event) => {
                        event.preventDefault();

                        if (lowerValue !== initialLowerValue || higherValue !== initialHigherValue) {
                            window.location = form.action
                                .replace('___FROM___', lowerValue)
                                .replace('___TO___', higherValue);
                        }
                    });

                    const minOutput = facet.querySelector('output[for=' + minSlider.id + ']');
                    const maxOutput = facet.querySelector('output[for=' + maxSlider.id + ']');

                    minSlider.addEventListener('input', (ev) => {
                        lowerValue = parseFloat(minSlider.value);
                        higherValue = parseFloat(maxSlider.value);

                        if (lowerValue > higherValue - rangeGap) {
                            maxSlider.value = lowerValue + rangeGap;
                            higherValue = parseFloat(maxSlider.value);

                            if (higherValue === parseFloat(maxSlider.max)) {
                                minSlider.value = parseFloat(maxSlider.max) - rangeGap;
                                updateOutputContent();
                                ev.preventDefault();
                                return false;
                            }
                        }

                        updateOutputContent();
                    });

                    maxSlider.addEventListener('input', (ev) => {
                        lowerValue = parseFloat(minSlider.value);
                        higherValue = parseFloat(maxSlider.value);

                        if (higherValue < lowerValue + rangeGap) {
                            minSlider.value = higherValue - rangeGap;
                            lowerValue = parseFloat(minSlider.value);

                            if (lowerValue === parseFloat(minSlider.min)) {
                                maxSlider.value = rangeGap;
                                updateOutputContent();
                                ev.preventDefault();
                                return false;
                            }
                        }

                        updateOutputContent();
                    });

                    function updateOutputContent() {
                        if (minOutput) {
                            lowerValue = parseFloat(minSlider.value);
                            minOutput.textContent =
                                '€ ' +
                                lowerValue.toLocaleString('de', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                        }
                        if (maxOutput) {
                            higherValue = parseFloat(maxSlider.value);
                            maxOutput.textContent =
                                '€ ' +
                                higherValue.toLocaleString('de', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                });
                        }
                    }
                }

                function closeFacet() {
                    facet.classList.remove(FACET_ACTIVE_CLASSNAME);
                    toggle.setAttribute('aria-expanded', 'false');
                    optionList?.classList.remove(FACET_OPTION_LIST_ALL_CLASSNAME);
                    if (showAllButton) {
                        showAllButton.textContent = showAllButton.dataset.labelMore;
                    }
                    form?.requestSubmit();
                }
            });
        },
    };
})(jQuery);
