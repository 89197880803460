/* Galleriffic v.2.0.1 */
!(function (t) {
    var e = {},
        n = 0;
    t.galleriffic = {
        version: '2.0.1',
        normalizeHash: function (t) {
            return t.replace(/^.*#/, '').replace(/\?.*$/, '');
        },
        getImage: function (i) {
            return i ? ((i = t.galleriffic.normalizeHash(i)), e[i]) : void 0;
        },
        gotoImage: function (e) {
            var i = t.galleriffic.getImage(e);
            if (!i) return !1;
            var n = i.gallery;
            return n.gotoImage(i), !0;
        },
        removeImageByHash: function (e, i) {
            var n = t.galleriffic.getImage(e);
            if (!n) return !1;
            var a = n.gallery;
            return i && i != a ? !1 : a.removeImageByIndex(n.index);
        },
    };
    var a = {
        delay: 3e3,
        numThumbs: 20,
        preloadAhead: 40,
        enableTopPager: !1,
        enableBottomPager: !0,
        maxPagesToShow: 7,
        imageContainerSel: '',
        captionContainerSel: '',
        controlsContainerSel: '',
        loadingContainerSel: '',
        renderSSControls: !0,
        renderNavControls: !0,
        playLinkText: 'Play',
        pauseLinkText: 'Pause',
        prevLinkText: 'Previous',
        nextLinkText: 'Next',
        nextPageLinkText: 'Next &rsaquo;',
        prevPageLinkText: '&lsaquo; Prev',
        enableHistory: !1,
        enableKeyboardNavigation: !0,
        autoStart: !1,
        syncTransitions: !1,
        defaultTransitionDuration: 1e3,
        onSlideChange: void 0,
        onTransitionOut: void 0,
        onTransitionIn: void 0,
        onPageTransitionOut: void 0,
        onPageTransitionIn: void 0,
        onImageAdded: void 0,
        onImageRemoved: void 0,
    };
    t.fn.galleriffic = function (s) {
        t.extend(this, {
            version: t.galleriffic.version,
            isSlideshowRunning: !1,
            slideshowTimeout: void 0,
            clickHandler: function (e, i) {
                if ((this.pause(), !this.enableHistory)) {
                    var n = t.galleriffic.normalizeHash(t(i).attr('href'));
                    t.galleriffic.gotoImage(n), e.preventDefault();
                }
            },
            appendImage: function (t) {
                return this.addImage(t, !1, !1), this;
            },
            insertImage: function (t, e) {
                return this.addImage(t, !1, !0, e), this;
            },
            addImage: function (i, a, s, r) {
                var o = 'string' == typeof i ? t(i) : i,
                    h = o.find('a.thumb'),
                    l = h.attr('href'),
                    d = h.attr('title'),
                    u = o.find('.caption').remove(),
                    c = h.attr('name');
                n++, (!c || e['' + c]) && (c = n), s || (r = this.data.length);
                var g = { title: d, slideUrl: l, caption: u, hash: c, gallery: this, index: r };
                s ? (this.data.splice(r, 0, g), this.updateIndices(r)) : this.data.push(g);
                var f = this;
                return (
                    a ||
                        this.updateThumbs(function () {
                            var t = f.find('ul.thumbs');
                            s ? t.children(':eq(' + r + ')').before(o) : t.append(o),
                                f.onImageAdded && f.onImageAdded(g, o);
                        }),
                    (e['' + c] = g),
                    h
                        .attr('rel', 'history')
                        .attr('href', '#' + c)
                        .removeAttr('name')
                        .click(function (t) {
                            f.clickHandler(t, this);
                        }),
                    this
                );
            },
            removeImageByIndex: function (t) {
                if (0 > t || t >= this.data.length) return !1;
                var e = this.data[t];
                return e ? (this.removeImage(e), !0) : !1;
            },
            removeImageByHash: function (e) {
                return t.galleriffic.removeImageByHash(e, this);
            },
            removeImage: function (t) {
                var i = t.index;
                return (
                    this.data.splice(i, 1),
                    delete e['' + t.hash],
                    this.updateThumbs(function () {
                        var e = r
                            .find('ul.thumbs')
                            .children(':eq(' + i + ')')
                            .remove();
                        r.onImageRemoved && r.onImageRemoved(t, e);
                    }),
                    this.updateIndices(i),
                    this
                );
            },
            updateIndices: function (t) {
                for (i = t; i < this.data.length; i++) this.data[i].index = i;
                return this;
            },
            initializeThumbs: function () {
                this.data = [];
                var e = this;
                return (
                    this.find('ul.thumbs > li').each(function (i) {
                        e.addImage(t(this), !0, !1);
                    }),
                    this
                );
            },
            isPreloadComplete: !1,
            preloadInit: function () {
                if (0 == this.preloadAhead) return this;
                this.preloadStartIndex = this.currentImage.index;
                var t = this.getNextIndex(this.preloadStartIndex);
                return this.preloadRecursive(this.preloadStartIndex, t);
            },
            preloadRelocate: function (t) {
                return (this.preloadStartIndex = t), this;
            },
            preloadRecursive: function (t, e) {
                if (t != this.preloadStartIndex) {
                    var i = this.getNextIndex(this.preloadStartIndex);
                    return this.preloadRecursive(this.preloadStartIndex, i);
                }
                var n = this,
                    a = e - t;
                if ((0 > a && (a = this.data.length - 1 - t + e), this.preloadAhead >= 0 && a > this.preloadAhead))
                    return (
                        setTimeout(function () {
                            n.preloadRecursive(t, e);
                        }, 500),
                        this
                    );
                var s = this.data[e];
                if (!s) return this;
                if (s.image) return this.preloadNext(t, e);
                var r = new Image();
                return (
                    (r.onload = function () {
                        (s.image = this), n.preloadNext(t, e);
                    }),
                    (r.alt = s.title),
                    (r.src = s.slideUrl),
                    this
                );
            },
            preloadNext: function (t, e) {
                var i = this.getNextIndex(e);
                if (i == t) this.isPreloadComplete = !0;
                else {
                    var n = this;
                    setTimeout(function () {
                        n.preloadRecursive(t, i);
                    }, 100);
                }
                return this;
            },
            getNextIndex: function (t) {
                var e = t + 1;
                return e >= this.data.length && (e = 0), e;
            },
            getPrevIndex: function (t) {
                var e = t - 1;
                return 0 > e && (e = this.data.length - 1), e;
            },
            pause: function () {
                return (
                    (this.isSlideshowRunning = !1),
                    this.slideshowTimeout && (clearTimeout(this.slideshowTimeout), (this.slideshowTimeout = void 0)),
                    this.$controlsContainer &&
                        this.$controlsContainer
                            .find('div.ss-controls a')
                            .removeClass()
                            .addClass('play')
                            .attr('title', this.playLinkText)
                            .attr('href', '#play')
                            .html(this.playLinkText),
                    this
                );
            },
            play: function () {
                if (
                    ((this.isSlideshowRunning = !0),
                    this.$controlsContainer &&
                        this.$controlsContainer
                            .find('div.ss-controls a')
                            .removeClass()
                            .addClass('pause')
                            .attr('title', this.pauseLinkText)
                            .attr('href', '#pause')
                            .html(this.pauseLinkText),
                    !this.slideshowTimeout)
                ) {
                    var t = this;
                    this.slideshowTimeout = setTimeout(function () {
                        t.ssAdvance();
                    }, this.delay);
                }
                return this;
            },
            toggleSlideshow: function () {
                return this.isSlideshowRunning ? this.pause() : this.play(), this;
            },
            ssAdvance: function () {
                return this.isSlideshowRunning && this.next(!0), this;
            },
            next: function (t, e) {
                return this.gotoIndex(this.getNextIndex(this.currentImage.index), t, e), this;
            },
            previous: function (t, e) {
                return this.gotoIndex(this.getPrevIndex(this.currentImage.index), t, e), this;
            },
            nextPage: function (t, e) {
                var i = this.getCurrentPage(),
                    n = this.getNumPages() - 1;
                if (n > i) {
                    var a = i * this.numThumbs,
                        s = a + this.numThumbs;
                    this.gotoIndex(s, t, e);
                }
                return this;
            },
            previousPage: function (t, e) {
                var i = this.getCurrentPage();
                if (i > 0) {
                    var n = i * this.numThumbs,
                        a = n - this.numThumbs;
                    this.gotoIndex(a, t, e);
                }
                return this;
            },
            gotoIndex: function (e, i, n) {
                i || this.pause(), 0 > e ? (e = 0) : e >= this.data.length && (e = this.data.length - 1);
                var a = this.data[e];
                return !n && this.enableHistory ? t.historyLoad(String(a.hash)) : this.gotoImage(a), this;
            },
            gotoImage: function (t) {
                var e = t.index;
                return (
                    this.onSlideChange && this.onSlideChange(this.currentImage.index, e),
                    (this.currentImage = t),
                    this.preloadRelocate(e),
                    this.refresh(),
                    this
                );
            },
            getDefaultTransitionDuration: function (t) {
                return t ? this.defaultTransitionDuration : this.defaultTransitionDuration / 2;
            },
            refresh: function () {
                var t = this.currentImage;
                if (!t) return this;
                var e = t.index;
                this.$controlsContainer &&
                    this.$controlsContainer
                        .find('div.nav-controls a.prev')
                        .attr('href', '#' + this.data[this.getPrevIndex(e)].hash)
                        .end()
                        .find('div.nav-controls a.next')
                        .attr('href', '#' + this.data[this.getNextIndex(e)].hash);
                var i = this.$imageContainer.find('span.current').addClass('previous').removeClass('current'),
                    n = 0;
                this.$captionContainer &&
                    (n = this.$captionContainer.find('span.current').addClass('previous').removeClass('current'));
                var a = this.syncTransitions && t.image,
                    s = !0,
                    r = this,
                    o = function () {
                        (s = !1),
                            i.remove(),
                            n && n.remove(),
                            a ||
                                (t.image && t.hash == r.data[r.currentImage.index].hash
                                    ? r.buildImage(t, a)
                                    : r.$loadingContainer && r.$loadingContainer.show());
                    };
                if (
                    (0 == i.length
                        ? o()
                        : this.onTransitionOut
                          ? this.onTransitionOut(i, n, a, o)
                          : (i.fadeTo(this.getDefaultTransitionDuration(a), 0, o),
                            n && n.fadeTo(this.getDefaultTransitionDuration(a), 0)),
                    a && this.buildImage(t, a),
                    !t.image)
                ) {
                    var h = new Image();
                    (h.onload = function () {
                        (t.image = this), s || t.hash != r.data[r.currentImage.index].hash || r.buildImage(t, a);
                    }),
                        (h.alt = t.title),
                        (h.src = t.slideUrl);
                }
                return (this.relocatePreload = !0), this.syncThumbs();
            },
            buildImage: function (t, e) {
                var i = this,
                    n = this.getNextIndex(t.index),
                    a = this.$imageContainer
                        .append(
                            '<span class="image-wrapper current"><a class="magnificPopup" rel="lightbox[' +
                                this.data[n].hash +
                                ']" href="' +
                                t.slideUrl +
                                '" title="' +
                                t.title +
                                '"></a></span>',
                        )
                        .find('span.current')
                        .css('opacity', '0');
                a.find('a')
                    .append(t.image)
                    .click(function (t) {
                        i.clickHandler(t, this);
                    });
                var s = 0;
                return (
                    this.$captionContainer &&
                        (s = this.$captionContainer
                            .append('<span class="image-caption current"></span>')
                            .find('span.current')
                            .css('opacity', '0')
                            .append(t.caption)),
                    this.$loadingContainer && this.$loadingContainer.hide(),
                    this.onTransitionIn
                        ? this.onTransitionIn(a, s, e)
                        : (a.fadeTo(this.getDefaultTransitionDuration(e), 1),
                          s && s.fadeTo(this.getDefaultTransitionDuration(e), 1)),
                    this.isSlideshowRunning &&
                        (this.slideshowTimeout && clearTimeout(this.slideshowTimeout),
                        (this.slideshowTimeout = setTimeout(function () {
                            i.ssAdvance();
                        }, this.delay))),
                    this
                );
            },
            getCurrentPage: function () {
                return Math.floor(this.currentImage.index / this.numThumbs);
            },
            syncThumbs: function () {
                var t = this.getCurrentPage();
                t != this.displayedPage && this.updateThumbs();
                var e = this.find('ul.thumbs').children();
                return (
                    e.filter('.selected').removeClass('selected'),
                    e.eq(this.currentImage.index).addClass('selected'),
                    this
                );
            },
            updateThumbs: function (t) {
                var e = this,
                    i = function () {
                        t && t(), e.rebuildThumbs(), e.onPageTransitionIn ? e.onPageTransitionIn() : e.show();
                    };
                return this.onPageTransitionOut ? this.onPageTransitionOut(i) : (this.hide(), i()), this;
            },
            rebuildThumbs: function () {
                var e = this.data.length > this.numThumbs;
                if (this.enableTopPager) {
                    var i = this.find('div.top');
                    0 == i.length
                        ? (i = this.prepend('<div class="top pagination"></div>').find('div.top'))
                        : i.empty(),
                        e && this.buildPager(i);
                }
                if (this.enableBottomPager) {
                    var n = this.find('div.bottom');
                    0 == n.length
                        ? (n = this.append('<div class="bottom pagination"></div>').find('div.bottom'))
                        : n.empty(),
                        e && this.buildPager(n);
                }
                var a = this.getCurrentPage(),
                    s = a * this.numThumbs,
                    r = s + this.numThumbs - 1;
                r >= this.data.length && (r = this.data.length - 1);
                var o = this.find('ul.thumbs');
                return (
                    o.find('li').each(function (e) {
                        var i = t(this);
                        e >= s && r >= e ? i.show() : i.hide();
                    }),
                    (this.displayedPage = a),
                    o.removeClass('noscript'),
                    this
                );
            },
            getNumPages: function () {
                return Math.ceil(this.data.length / this.numThumbs);
            },
            buildPager: function (t) {
                var e = this,
                    i = this.getNumPages(),
                    n = this.getCurrentPage(),
                    a = n * this.numThumbs,
                    s = this.maxPagesToShow - 1,
                    r = n - Math.floor((this.maxPagesToShow - 1) / 2) + 1;
                if (r > 0) {
                    var o = i - r;
                    s > o && (r -= s - o);
                }
                if ((0 > r && (r = 0), n > 0)) {
                    var h = a - this.numThumbs;
                    t.append(
                        '<a rel="history" href="#' +
                            this.data[h].hash +
                            '" title="' +
                            this.prevPageLinkText +
                            '">' +
                            this.prevPageLinkText +
                            '</a>',
                    );
                }
                for (
                    r > 0 &&
                    (this.buildPageLink(t, 0, i), r > 1 && t.append('<span class="ellipsis">&hellip;</span>'), s--);
                    s > 0;

                )
                    this.buildPageLink(t, r, i), s--, r++;
                if (i > r) {
                    var l = i - 1;
                    l > r && t.append('<span class="ellipsis">&hellip;</span>'), this.buildPageLink(t, l, i);
                }
                var d = a + this.numThumbs;
                return (
                    d < this.data.length &&
                        t.append(
                            '<a rel="history" href="#' +
                                this.data[d].hash +
                                '" title="' +
                                this.nextPageLinkText +
                                '">' +
                                this.nextPageLinkText +
                                '</a>',
                        ),
                    t.find('a').click(function (t) {
                        e.clickHandler(t, this);
                    }),
                    this
                );
            },
            buildPageLink: function (t, e, i) {
                var n = e + 1,
                    a = this.getCurrentPage();
                if (e == a) t.append('<span class="current">' + n + '</span>');
                else if (i > e) {
                    var s = e * this.numThumbs;
                    t.append('<a rel="history" href="#' + this.data[s].hash + '" title="' + n + '">' + n + '</a>');
                }
                return this;
            },
        }),
            t.extend(this, a, s),
            this.enableHistory && !t.historyInit && (this.enableHistory = !1),
            this.imageContainerSel && (this.$imageContainer = t(this.imageContainerSel)),
            this.captionContainerSel && (this.$captionContainer = t(this.captionContainerSel)),
            this.loadingContainerSel && (this.$loadingContainer = t(this.loadingContainerSel)),
            this.initializeThumbs(),
            this.maxPagesToShow < 3 && (this.maxPagesToShow = 3),
            (this.displayedPage = -1),
            (this.currentImage = this.data[0]);
        var r = this;
        this.$loadingContainer && this.$loadingContainer.hide(),
            this.controlsContainerSel &&
                ((this.$controlsContainer = t(this.controlsContainerSel).empty()),
                this.renderSSControls &&
                    (this.$controlsContainer.append(
                        this.autoStart
                            ? '<div class="ss-controls"><a href="#pause" class="pause" title="' +
                                  this.pauseLinkText +
                                  '">' +
                                  this.pauseLinkText +
                                  '</a></div>'
                            : '<div class="ss-controls"><a href="#play" class="play" title="' +
                                  this.playLinkText +
                                  '">' +
                                  this.playLinkText +
                                  '</a></div>',
                    ),
                    this.$controlsContainer.find('div.ss-controls a').click(function (t) {
                        return r.toggleSlideshow(), t.preventDefault(), !1;
                    })),
                this.renderNavControls &&
                    this.$controlsContainer
                        .append(
                            '<div class="nav-controls"><a class="prev" rel="history" title="' +
                                this.prevLinkText +
                                '">' +
                                this.prevLinkText +
                                '</a><a class="next" rel="history" title="' +
                                this.nextLinkText +
                                '">' +
                                this.nextLinkText +
                                '</a></div>',
                        )
                        .find('div.nav-controls a')
                        .click(function (t) {
                            r.clickHandler(t, this);
                        }));
        var o = !this.enableHistory || !location.hash;
        if (this.enableHistory && location.hash) {
            var h = t.galleriffic.normalizeHash(location.hash),
                l = e[h];
            l || (o = !0);
        }
        return (
            o && this.gotoIndex(0, !1, !0),
            this.enableKeyboardNavigation &&
                t(document).keydown(function (t) {
                    var e = t.charCode ? t.charCode : t.keyCode ? t.keyCode : 0;
                    switch (e) {
                        case 32:
                            r.next(), t.preventDefault();
                            break;
                        case 33:
                            r.previousPage(), t.preventDefault();
                            break;
                        case 34:
                            r.nextPage(), t.preventDefault();
                            break;
                        case 35:
                            r.gotoIndex(r.data.length - 1), t.preventDefault();
                            break;
                        case 36:
                            r.gotoIndex(0), t.preventDefault();
                            break;
                        case 37:
                            r.previous(), t.preventDefault();
                            break;
                        case 39:
                            r.next(), t.preventDefault();
                    }
                }),
            this.autoStart && this.play(),
            setTimeout(function () {
                r.preloadInit();
            }, 1e3),
            this
        );
    };
})(jQuery);
/* Opacity Rollover */
!(function (e) {
    var t = { mouseOutOpacity: 0.67, mouseOverOpacity: 1, fadeSpeed: 'fast', exemptionSelector: '.selected' };
    e.fn.opacityrollover = function (o) {
        function i(t, o) {
            var i = e(t);
            c.exemptionSelector && (i = i.not(c.exemptionSelector)), i.fadeTo(c.fadeSpeed, o);
        }
        e.extend(this, t, o);
        var c = this;
        return (
            this.css('opacity', this.mouseOutOpacity).hover(
                function () {
                    i(this, c.mouseOverOpacity);
                },
                function () {
                    i(this, c.mouseOutOpacity);
                },
            ),
            this
        );
    };
})(jQuery);
