(function ($) {
    'use strict';

    $.themeSlider = {
        init() {
            const splideElements = Array.from(document.querySelectorAll('.theme-slider__container'));

            if (splideElements.length > 0) {
                splideElements.forEach((themeSlider) => {
                    const sliderItemContainer = document.querySelector('.splide__list');
                    const ariaLabels = [];
                    for (let i = 0; i < sliderItemContainer.children.length; i++) {
                        ariaLabels.push(sliderItemContainer.children[i].getAttribute('aria-label'));
                    }
                    for (let i = sliderItemContainer.children.length; i >= 0; i--) {
                        sliderItemContainer.appendChild(sliderItemContainer.children[(Math.random() * i) | 0]);
                    }
                    for (let i = 0; i < sliderItemContainer.children.length; i++) {
                        sliderItemContainer.children[i].setAttribute('aria-label', ariaLabels[i]);
                    }

                    let splide = new Splide(themeSlider, {
                        arrows: false,
                        perPage: 1,
                        type: 'loop',
                        pagination: 'theme-slider__container',
                        keyboard: false, // Splide listens to key events at the document level and moves ALL carousels when arrow keys are used. Since keyboard and screen reader users use these keys to operate the tabs, this creates a very unusual experience.
                        slideFocus: false, // removes tabindex="0" from each slide wrapper, since we only want our links inside each slide to receive focus.
                        autoplay: true,
                    }).mount();

                    // To prevent animation issues, let's make every slide visible before a transition happens. Splide will then automatically remove the `.is-visible` class from non-visible slides once the transition is finished.
                    splide.on('move', function () {
                        const slides = themeSlider.querySelectorAll('.splide .splide__slide');

                        slides.forEach(function (slide) {
                            slide.classList.add('is-visible');
                        });
                    });

                    let sliderParent = themeSlider.parentElement;
                    let sliderPagination = themeSlider.querySelector('.splide__pagination');
                    let previousButton = sliderParent.querySelector('.theme-slider .previous-button');
                    let nextButton = sliderParent.querySelector('.theme-slider .next-button');

                    // Go to the previous slide when the Previous button is activated
                    previousButton.addEventListener('click', (e) => {
                        splide.go('<');
                    });

                    // Go to the next slide when the Next button is activated
                    nextButton.addEventListener('click', (e) => {
                        splide.go('>');
                    });

                    // move pagination to a desired place
                    let paginationWrapper = sliderParent.querySelector('.splide__pagination-wrapper');
                    paginationWrapper.insertAdjacentElement('beforebegin', sliderPagination);

                    const Autoplay = splide.Components.Autoplay;

                    const slideParent = themeSlider.parentElement;

                    const pausePlayButton = slideParent.querySelector('.splide__pause-play');

                    const sliderAnimation = {
                        play: () => {
                            Autoplay.play();
                            pausePlayButton.setAttribute('data-action', 'play');
                            pausePlayButton.innerText = 'Pause';
                        },
                        pause: () => {
                            Autoplay.pause();
                            pausePlayButton.setAttribute('data-action', 'pause');
                            pausePlayButton.innerText = 'Play';
                        },
                    };

                    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

                    if (!mediaQuery && !mediaQuery.matches) {
                        sliderAnimation.pause();
                    } else {
                        sliderAnimation.play();
                    }

                    mediaQuery.addEventListener('change', () => {
                        if (!mediaQuery || !mediaQuery.matches) {
                            sliderAnimation.pause();
                        } else {
                            sliderAnimation.play();
                        }
                    });

                    themeSlider.addEventListener('focusin', () => {
                        if (!mediaQuery || !mediaQuery.matches) {
                            sliderAnimation.pause();
                        }
                    });

                    themeSlider.addEventListener('focusout', (event) => {
                        const isRelatedTargetInsideSlider = !!themeSlider
                            .closest('.theme-slider')
                            .contains(event.relatedTarget);
                        if ((!mediaQuery || !mediaQuery.matches) && !isRelatedTargetInsideSlider) {
                            sliderAnimation.play();
                        }
                    });

                    const pausePlay = () => {
                        if (pausePlayButton.getAttribute('data-action') === 'play') {
                            sliderAnimation.pause();
                        } else {
                            sliderAnimation.play();
                        }
                    };

                    pausePlayButton.addEventListener('click', () => {
                        pausePlay();
                    });

                    pausePlayButton.addEventListener('keydown', (e) => {
                        if (e.which === 16) {
                            pausePlay();
                        }
                    });
                });
            }
        },
    };
})(jQuery);
